import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import Layout from "../components/Layout";

export const ManagementPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <section className="home-hero">
      <InView threshold={0.2}>
        {({ ref, inView }) => (
          <motion.div
            ref={ref}
            initial={{ opacity: 0, transform: "translate(-50px)" }}
            animate={
              inView
                ? { opacity: 1, transform: "translate(0px)" }
                : { opacity: 1 }
            }
            transition={{
              duration: 0.5,
              delay: 0.25,
            }}
            className="ontopyo"
          >
            <h1
              data-scroll
              data-scroll-direction="horizontal"
              data-scroll-speed="2"
              data-scroll-position="top"
              data-scroll-delay="0.2"
            >
              Management
            </h1>
          </motion.div>
        )}
      </InView>

      <div className="scroll-line">
        <div className="center" />
        
        <div
          data-scroll
          data-scroll-speed="-1"
          className="marker" 
          />
      </div>

      <div className="video-container">
        <div className="video-overlay" />
        <img src="../img/hero-management.jpg"/>
      </div>
    </section>
    <div className="about-heli">
      <div className="intro-heli">
      <InView threshold={0.2}>
        {({ ref, inView }) => (
          <motion.div
            ref={ref}
            initial={{ opacity: 0 }}
            animate={
              inView
                ? { opacity: 1 }
                : { opacity: 1 }
            }
            transition={{
              duration: 0.25,
              delay: 0.1,
            }}
          
          >
        <p data-scroll data-scroll-speed="-1" data-scroll-delay="0.2">
          Excel Helicopters provide charter flights from any UK. location.
          Whether business or pleasure, helicopter charter or private helicopter
          hire is the most time-efficient and memorable way to travel.
        </p>
        </motion.div>
        )}
      </InView>
      <InView threshold={0.2}>
        {({ ref, inView }) => (
          <motion.h1
            ref={ref}
            initial={{ opacity: 0 }}
            animate={
              inView
                ? { opacity: 1 }
                : { opacity: 1 }
            }
            transition={{
              duration: 0.25,
              delay: 0.1,
            }}
          
          >
          <span data-scroll data-scroll-speed="1.5" data-scroll-delay="0.2">Content</span>
          <span data-scroll data-scroll-speed="1" data-scroll-delay="0.4">TBC</span>
          </motion.h1>
        )}
      </InView>
      </div>
    </div>
    

  </div>
);

ManagementPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const ManagementPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ManagementPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

ManagementPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ManagementPage;

export const pageQuery = graphql`
  query ManagementPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            text
          }
          heading
          description
        }
      }
    }
  }
`;
